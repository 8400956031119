import { Component, OnInit,Input,ChangeDetectorRef,ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable,Subject } from 'rxjs';
import { map, take, tap,takeUntil } from 'rxjs/operators';
import { MainNav } from '../../models/main-nav.model';
import { MainMenuService } from '../../services/main-menu.service';
import { UserdatasharingService } from '../../services/userdatasharing.service';
import { CallUsRequestService } from '../../services/call-us-request.service';
import { WorkOrderRequestService } from 'src/app/modules/shared-request/services/work-order-request.service';
import { WorkOrderModel } from 'src/app/modules/shared-request/models/workOrderModel';

@Component({
  selector: 'app-side-nav-tree',
  templateUrl: './side-nav-tree.component.html',
  styleUrls: ['./side-nav-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class SideNavTreeComponent implements OnInit {
  mainMenu: MainNav[];
  activeSection = '';
  @Input() moduleOptions: any;
  isSurveyCheck:boolean;
  WorkOrders: Array<WorkOrderModel> = null;
  private unsubscribe$ = new Subject<void>();


  constructor(private router: Router,
    public userService: UserdatasharingService,
    private mainMenuService: MainMenuService,
    public callUsRequestService: CallUsRequestService,
    private workOrderRequestService: WorkOrderRequestService,
    private cdr :ChangeDetectorRef
  ) {
  }

async  ngOnInit() {
  this.isSurveyCheck = false;
  this.subscribeToData();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  subscribeToData(): void {
    this.workOrderRequestService.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.WorkOrders = data;
        const workorder = this.WorkOrders.filter(x => !x.ISSurveydisabled);

        if (data.length === 0 && workorder.length === 0) {
          this.isSurveyCheck = true;
        } else if (this.WorkOrders.length > 0 && workorder.length === 0) {
          this.isSurveyCheck = true;
        }
        else{
          this.isSurveyCheck = false;
        }
          this.getMenu();
        this.cdr.detectChanges();
      });
  }

  getMenu(): void {
    this.mainMenuService.getMenuItems()
      .pipe(
        take(1),
        map(menuItems => {
          if (this.moduleOptions && this.moduleOptions.SurveyEnable === false) {
            return menuItems.filter(item => item.name !== 'Survey');
          } 
          else if(this.isSurveyCheck == true){
            return menuItems.filter(item => item.name !== 'Survey');
          }
          else {
            return menuItems;
          }
        })
      )
      .subscribe(filteredMenuItems => {
        this.mainMenu = filteredMenuItems;
      });
  }
  
  toggleSection(section: string) {
    if (this.activeSection !== section) {
      this.activeSection = section;
    } else {
      this.activeSection = '';
    }
    //console.log(this.activeSection);
  }
  
  get isAuthenticated(): Observable<boolean> {
    return this.userService.isAuthenticated();
  }
  
  logout(): void {
    this.userService.clear();
    this.router.navigateByUrl('/login');
  }
  

}
