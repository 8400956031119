import { Component, ChangeDetectionStrategy,Input,ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { UserdatasharingService } from 'src/app/modules/core/services/userdatasharing.service';
import { CallUsRequestService } from 'src/app/modules/core/services/call-us-request.service';
import { WorkOrderRequestService } from 'src/app/modules/shared-request/services/work-order-request.service';
import { WorkOrderModel } from 'src/app/modules/shared-request/models/workOrderModel';

@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomNavComponent {

  visible = false;
  @Input() moduleOptions: any;
  isSurveyCheck:boolean;
  WorkOrders: Array<WorkOrderModel> = null;
  
  constructor(private userService: UserdatasharingService,
    public callUsRequestService: CallUsRequestService,
    private workOrderRequestService: WorkOrderRequestService,
    private cdr :ChangeDetectorRef) {
  }
  async  ngOnInit() {
    this.isSurveyCheck = false;
    this.subscribeToData();

}
subscribeToData(): void {
  this.workOrderRequestService.data$.subscribe(data => {
    this.WorkOrders = data;
    const workorder = this.WorkOrders.filter(x=>x.ISSurveydisabled == false);
    if(data.length ==0 && workorder.length == 0){
      this.isSurveyCheck = true;
      this.cdr.detectChanges();
    }
    else if(this.WorkOrders.length >0 && workorder.length == 0){
      this.isSurveyCheck = true;
      this.cdr.detectChanges();
  }
  else{
    this.isSurveyCheck = false;
    this.cdr.detectChanges();
  }
  });
}
  get isAuthenticated(): Observable<boolean> {
    return this.userService.isAuthenticated();
  }
}
